<template>
  <div>
    <v-alert v-if="isActiveAlerts" class="Active-alerts-info">
      {{ $t('alertLogAutoCloseInfo') }}
    </v-alert>
    <v-alert v-else class="Closed-alerts-info">
      {{ $t('alertLogAutoDeleteInfo') }}
    </v-alert>
    <div v-if="isAdmin">
      <v-switch v-model="showCompanyAlerts" :label="$t('showAllAlerts')" />
      <p v-if="showCompanyAlerts === true">{{ $t('showingAllAlerts') }}</p>
      <p v-else>{{ $t('showingMyAlerts') }}</p>
    </div>
    <v-data-iterator
      :items="alerts"
      :items-per-page.sync="itemsPerPage"
      :options.sync="options"
      :server-items-length="totalAlerts"
      hide-default-footer
    >
      <template #no-data>
        <empty-state
          v-if="isActiveAlerts"
          :title="$t('noActiveAlerts')"
          :message="$t('noActiveAlertsMsg')"
          :icon-src="require('@/assets/images/empty-states/alerts.svg')"
        />
        <empty-state
          v-else
          :title="$t('noClosedAlerts')"
          :message="$t('noClosedAlertsMsg')"
          :icon-src="require('@/assets/images/empty-states/alerts.svg')"
        />
      </template>
      <template #default="props">
        <v-row>
          <v-col v-for="item in props.items" :key="item.name" cols="12" sm="12" md="12" lg="6">
            <v-card class="alert-card">
              <alert-card :alert="item" @viewDetails="openDetails(item)" />
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <div v-if="alerts.length > 0" class="text-center pagination">
      <v-pagination v-model="page" :length="pageCount" circle />
    </div>
  </div>
</template>

<script>
import api from '@/util/api';
import AlertCard from './AlertCard.vue';
import auth from '@/util/auth';
import { isAdmin } from '@/consts/roles';
import getUserProfile from '@/util/getUserProfile';
import EmptyState from './EmptyState.vue';

export default {
  name: 'AlertList',

  components: {
    AlertCard,
    EmptyState,
  },
  props: {
    alertStatuses: {
      type: Array,
      required: false,
      default: () => ['active'],
    },
  },
  data: () => ({
    options: {},
    search: '',
    loading: false,
    events: [],
    itemsPerPage: 20,
    totalAlerts: 0,
    pageCount: 1,
    page: 1,
    alerts: [],
    showCompanyAlerts: false,
    isAdmin: false,
  }),
  computed: {
    isActiveAlerts() {
      if (this.alertStatuses.indexOf('active') > -1) return true;
      else return false;
    },
  },
  watch: {
    page: {
      handler() {
        this.getAlerts();
      },
    },
    showCompanyAlerts: {
      handler() {
        this.getAlerts();
      },
    },
  },
  async mounted() {
    const profile = await getUserProfile();
    this.isAdmin = isAdmin(profile.user.role);
    this.getAlerts();
    this.subscribeToSignalREvents();
  },
  beforeDestroy() {
    this.unregisterSignalR();
  },
  methods: {
    getAlerts() {
      this.loading = true;
      if (this.showCompanyAlerts) {
        api
          .getAllAlerts({
            statuses: this.alertStatuses,
            page: this.page,
            pageSize: this.itemsPerPage,
          })
          .then((res) => {
            this.alerts = res.alerts;
            this.totalAlerts = res.total;
            this.pageCount = res.pageCount;
            this.loading = false;
          });
      } else {
        api
          .getMyAlerts({
            statuses: this.alertStatuses,
            page: this.page,
            pageSize: this.itemsPerPage,
          })
          .then((res) => {
            this.alerts = res.alerts;
            this.totalAlerts = res.total;
            this.pageCount = res.pageCount;
            this.loading = false;
          });
      }
    },
    getAlertType(alertType) {
      if (alertType === 'missionAlarn') return 'red';
      else if (alertType === 'urgentAlert') return 'orange';
      else return 'red';
    },
    openDetails(value) {
      this.$router.push('/alerts/' + value.id);
    },
    subscribeToSignalREvents() {
      const customerId = auth.getCustomerId();
      this.events.forEach((event) => this.$signalR.off(event));

      const event = `newAlertCustomerId:${customerId}`;
      this.events.push(event);

      this.$signalR.on(event, () => {
        setTimeout(() => this.getAlerts(), 3000);
        // TODO: Replace with event from professor that alert har been processed
      });
    },
    unregisterSignalR() {
      this.events.forEach((event) => this.$signalR.off(event));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/scss/settings/colors';

@mixin banner-gradient($top, $bottom) {
  background: $top;
  background: -moz-linear-gradient(to bottom, $top 0%, $bottom 100%);
  background: -webkit-linear-gradient(to bottom, $top 0%, $bottom 100%);
  background: linear-gradient(to bottom, $top 0%, $bottom 100%);
}

.Active-alerts-info {
  border-radius: 8px;
  box-shadow: 2px 4px 4px #e4e4e4;

  border: 1px solid #d8b554;
  @include banner-gradient($colorYellowPrimary, #f1b037);
}
.Closed-alerts-info {
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  box-shadow: 2px 4px 4px #e4e4e4;

  @include banner-gradient(#dadada, #c5c5c5);
}
.alert-card {
  padding: 8px 8px 0px 8px;
}
.pagination {
  margin: 8px;
}
</style>
