<template>
  <!-- <div :class="{'d-flex': !fab}" class="bgr"> -->

  <div class="bgr">
    <v-btn v-if="fab" color="primary" fab fixed bottom right @click="exportDialog = true">
      <v-icon>fa-file-download</v-icon>
    </v-btn>
    <div v-else class="pa-2">
      <v-btn color="primary" @click="exportDialog = true">
        <v-icon left>fa-file-download</v-icon>{{ $t('export') }}
      </v-btn>
    </div>
    <v-tabs v-model="activeTab" grow>
      <v-tab v-for="(tab, index) in tabs" :key="index" class="bgr" :to="tab.path">
        {{ tab.title }}
      </v-tab>

      <v-tabs-items v-model="activeTab">
        <v-tab-item :value="tabs[0].path">
          <AlertList :alert-statuses="['active']" class="ma-4" />
        </v-tab-item>
        <v-tab-item :value="tabs[1].path">
          <AlertList :alert-statuses="['completed']" class="ma-4" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <v-dialog v-model="exportDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ $t('exportAlerts') }}</v-card-title>

        <v-card-text>
          <v-menu
            v-model="fromDialog"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="fromDisplay"
                :label="$t('from')"
                prepend-icon="fa-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="from"
              scrollable
              :min="minFromDate"
              :max="maxFromDate"
              first-day-of-week="1"
              @input="fromDialog = false"
            />
          </v-menu>

          <v-menu
            v-model="toDialog"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="toDisplay"
                :label="$t('to')"
                prepend-icon="fa-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="to"
              scrollable
              :min="minToDate"
              :max="maxToDate"
              first-day-of-week="1"
              @input="toDialog = false"
            />
          </v-menu>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text class="mr-1" :disabled="exporting" @click="cancelExport">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" :loading="exporting" @click="performExport">
            {{ $t('export') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { translate as t } from '@/util/i18n';
// import ActiveAlerts from './ActiveAlerts';
// import ClosedAlerts from './ClosedAlerts';
import moment from 'moment';
import saveBlob from '@/util/saveBlob';
import api from '@/util/api';
// import activeAlerts from './activeAlerts_module';
import AlertList from '@/components/AlertList';

export default {
  name: 'Alerts',

  components: {
    AlertList,
    // ActiveAlerts,
    // ClosedAlerts
  },

  data: () => ({
    events: [],
    exportDialog: null,
    fromDialog: null,
    toDialog: null,

    from: null,
    to: null,

    displayDateFormat: 'dddd, Do MMMM YYYY',

    exporting: false,

    activeTab: null,
    tabs: [
      {
        title: t('alertsActive'),
        path: '/alerts/active',
      },
      {
        title: t('alertsClosed'),
        path: '/alerts/closed',
      },
    ],
  }),
  computed: {
    fab() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    fromDisplay() {
      return moment(this.from).format(this.displayDateFormat);
    },

    toDisplay() {
      return moment(this.to).format(this.displayDateFormat);
    },

    minFromDate() {
      return this.convertto(moment(this.from));
    },

    minToDate() {
      return this.convertto(moment(this.from).add(1, 'day'));
    },

    maxFromDate() {
      return this.convertto(moment(this.to).subtract(1, 'day'));
    },

    maxToDate() {
      return this.convertto(moment());
    },
  },
  mounted() {
    // this.subscribeToSignalREvents(auth.getCustomerId());
    // this.getAlerts();
  },
  created() {
    this.reset();
  },

  methods: {
    reset() {
      this.from = this.convertto(moment().subtract(3, 'months'));
      this.to = this.convertto(moment());
    },

    cancelExport() {
      this.reset();
      this.exportDialog = false;
    },

    async performExport() {
      this.exporting = true;
      const from = this.from + 'T00:00:00.000+00:00';
      const to = this.to + 'T23:59:59.999+00:00';
      const blob = await api.getAlertLogExport(from, to);
      saveBlob(blob, `${this.$t('alertLogExportFileName')} ${this.from}-${this.to}.xlsx`);
      this.exporting = false;
      this.cancelExport();
    },

    convertto(momentInstance) {
      return momentInstance.format('YYYY-MM-DD');
    },

    // subscribeToSignalREvents(customerId) {
    //   this.events.forEach((event) => this.$signalR.off(event));

    //   const event = `newAlertCustomerId:${customerId}`;
    //   this.events.push(event);

    //   this.$signalR.on(event, () => {
    //     this.refreshComments();
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.bgr {
  background-color: #f1f1f1 !important;
}
.v-tabs-items {
  background-color: #f1f1f1;
}
</style>
